import classNames from 'classnames';
import { onSnapshot, collection } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import db from '../../firebase';
import styles from './new-courses-mobile.module.scss';
import {
  handleDeleteTeen,
  handleDeleteAdult,
  handleEditTeen,
  handleEditAdult,
  handleNewTeen,
  handleNewAdult,
  handleEditAdultTitle,
  handleEditAdultTimetable,
  handleEditAdultDate,
  handleEditAdultPlaces,
  handleEditTeenTitle,
  handleEditTeenTimetable,
  handleEditTeenDate,
  handleEditTeenPlaces,
  handleEditChildTitle,
  handleEditChildTimetable,
  handleEditChildDate,
  handleEditChildPlaces,
  handleEditChild,
  handleDeleteChild,
  handleEditAdultLang,
  handleEditTeenLang,
  handleEditChildrenLang,
  handleNewChild,
} from './Utils';
import { useNavigate } from 'react-router-dom';

const tabs = {
  ADULTS: 'Дорослі',
  TEENAGERS: 'Підлітки',
  CHILDREN: 'Діти',
};

const NewCoursesMobile = (props) => {
  const [teenCourse, setTeenCourse] = useState([
    { title: 'Loading...', id: 'initial' },
  ]);
  const [adultCourse, setAdultCourse] = useState([
    { title: 'Loading...', id: 'initial' },
  ]);
  const [childrenCourse, setChildrenCourse] = useState([
    { title: 'Loading...', id: 'initial' },
  ]);

  const [tab, setTab] = useState(tabs.ADULTS);
  const navList = Object.values(tabs);

  // ! Get infos from the data base
  useEffect(
    () =>
      onSnapshot(collection(db, 'teenagers'), (snapshot) => {
        setTeenCourse(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      }),
    []
  );

  useEffect(
    () =>
      onSnapshot(collection(db, 'adults'), (snapshot) => {
        setAdultCourse(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      }),
    []
  );
  useEffect(
    () =>
      onSnapshot(collection(db, 'children'), (snapshot) => {
        setChildrenCourse(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      }),
    []
  );

  return (
    <div className={classNames('wrapper', styles.wrapper)}>
      <h2 className={styles.mainHeading}>Актуальні набори в групи</h2>
      <nav className={styles.nav}>
        <ul className={styles.tabs}>
          {navList.map((el, index) => (
            <li
              key={`li-${index}`}
              onClick={() => setTab(el)}
              className={classNames(styles.el, tab === el && styles.clicked)}
            >
              {el}
            </li>
          ))}
        </ul>
      </nav>
      <div className={styles.table}>
        {tab === tabs.CHILDREN ? (
          <div className={styles.teenTable}>
            {childrenCourse.map((course) => (
              <div key={course.id} className={styles.card}>
                <div className={styles.textContainer}>
                  <div className={styles.leftContainer}>
                    <div className={styles.title}>{course.title}</div>
                    <button
                      onClick={() => handleEditChildTitle(course.id)}
                      className={styles.editBtn}
                    >
                      <span>Змінити назву</span>
                    </button>
                    <div className={styles.book}>
                      Розклад: {course.timetable}
                    </div>
                    <button
                      onClick={() => handleEditChildTimetable(course.id)}
                      className={styles.editBtn}
                    >
                      <span>Змінити розклад</span>
                    </button>
                    <div className={styles.teacher}>
                      Викладач: {course.teacher}
                    </div>
                    <button
                      onClick={() => handleEditChildDate(course.id)}
                      className={styles.editBtn}
                    >
                      <span>Змінити дату</span>
                    </button>
                  </div>
                  <div className={styles.rightContainer}>
                    <div className={styles.placesCon}>
                      <div className={styles.places}>
                        <span className={styles.maxPlaces}>
                          {course.places}/8
                        </span>
                      </div>
                      <button
                        onClick={() => handleEditChildPlaces(course.id)}
                        className={styles.editBtn}
                      >
                        <span>Змінити к-ть</span>
                      </button>
                    </div>
                    <div className={styles.lang}>
                      <span className={styles.langText}>{course.lang}</span>
                      <button
                        onClick={() => handleEditChildrenLang(course.id)}
                        className={styles.editBtn}
                      >
                        Змінити мову
                      </button>
                    </div>
                  </div>
                </div>
                <div className={styles.buttons}>
                  <button
                    onClick={() => handleEditChild(course.id)}
                    className={styles.editBtn}
                  >
                    <span>Змінити все</span>
                  </button>
                  <button
                    onClick={() => handleDeleteChild(course.id)}
                    className={styles.deleteBtn}
                  >
                    <span>Видалити</span>
                  </button>
                </div>
              </div>
            ))}
            <button onClick={handleNewChild} className={styles.newBtn}>
              <span>Додати дитячий курс</span>
            </button>
          </div>
        ) : (
          ''
        )}
        {tab === tabs.TEENAGERS ? (
          <div className={styles.teenTable}>
            {teenCourse.map((course) => (
              <div key={course.id} className={styles.card}>
                <div className={styles.textContainer}>
                  <div className={styles.leftContainer}>
                    <div className={styles.title}>{course.title}</div>
                    <button
                      onClick={() => handleEditTeenTitle(course.id)}
                      className={styles.editBtn}
                    >
                      <span>Змінити назву</span>
                    </button>
                    <div className={styles.book}>
                      Розклад: {course.timetable}
                    </div>
                    <button
                      onClick={() => handleEditTeenTimetable(course.id)}
                      className={styles.editBtn}
                    >
                      <span>Змінити розклад</span>
                    </button>
                    <div className={styles.teacher}>
                      Викладач: {course.teacher}
                    </div>
                    <button
                      onClick={() => handleEditTeenDate(course.id)}
                      className={styles.editBtn}
                    >
                      <span>Змінити дату</span>
                    </button>
                  </div>
                  <div className={styles.rightContainer}>
                    <div className={styles.placesCon}>
                      <div className={styles.places}>
                        <span className={styles.maxPlaces}>
                          {course.places}/8
                        </span>
                      </div>
                      <button
                        onClick={() => handleEditTeenPlaces(course.id)}
                        className={styles.editBtn}
                      >
                        <span>Змінити к-ть</span>
                      </button>
                    </div>
                    <div className={styles.lang}>
                      <span className={styles.langText}>{course.lang}</span>
                      <button
                        onClick={() => handleEditTeenLang(course.id)}
                        className={styles.editBtn}
                      >
                        Змінити мову
                      </button>
                    </div>
                  </div>
                </div>
                <div className={styles.buttons}>
                  <button
                    onClick={() => handleEditTeen(course.id)}
                    className={styles.editBtn}
                  >
                    <span>Змінити все</span>
                  </button>
                  <button
                    onClick={() => handleDeleteTeen(course.id)}
                    className={styles.deleteBtn}
                  >
                    <span>Видалити</span>
                  </button>
                </div>
              </div>
            ))}
            <button onClick={handleNewTeen} className={styles.newBtn}>
              <span>Додати підлітковий курс</span>
            </button>
          </div>
        ) : (
          ''
        )}
        {tab === tabs.ADULTS ? (
          <div className={styles.adultTable}>
            {adultCourse.map((course) => (
              <div key={course.id} className={styles.card}>
                <div className={styles.textContainer}>
                  <div className={styles.leftContainer}>
                    <div className={styles.title}>{course.title}</div>
                    <button
                      onClick={() => handleEditAdultTitle(course.id)}
                      className={styles.editBtn}
                    >
                      <span>Змінити назву</span>
                    </button>
                    <div className={styles.book}>
                      Розклад: {course.timetable}
                    </div>
                    <button
                      onClick={() => handleEditAdultTimetable(course.id)}
                      className={styles.editBtn}
                    >
                      <span>Змінити розклад</span>
                    </button>
                    <div className={styles.teacher}>
                      Викладач: {course.teacher}
                    </div>
                    <button
                      onClick={() => handleEditAdultDate(course.id)}
                      className={styles.editBtn}
                    >
                      <span>Змінити дату</span>
                    </button>
                  </div>
                  <div className={styles.rightContainer}>
                    <div className={styles.placesCon}>
                      <div className={styles.places}>
                        <span className={styles.maxPlaces}>
                          {course.places}/8
                        </span>
                      </div>
                      <button
                        onClick={() => handleEditAdultPlaces(course.id)}
                        className={styles.editBtn}
                      >
                        <span>Змінити к-ть</span>
                      </button>
                    </div>
                    <div className={styles.lang}>
                      <span className={styles.langText}>{course.lang}</span>
                      <button
                        onClick={() => handleEditAdultLang(course.id)}
                        className={styles.editBtn}
                      >
                        Змінити мову
                      </button>
                    </div>
                  </div>
                </div>
                <div className={styles.buttons}>
                  <button
                    onClick={() => handleEditAdult(course.id)}
                    className={styles.editBtn}
                  >
                    <span>Змінити все</span>
                  </button>
                  <button
                    onClick={() => handleDeleteAdult(course.id)}
                    className={styles.deleteBtn}
                  >
                    <span>Видалити</span>
                  </button>
                </div>
              </div>
            ))}
            <button onClick={handleNewAdult} className={styles.newBtn}>
              <span> Додати курс для дорослих</span>
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default NewCoursesMobile;
