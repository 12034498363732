import icons from './../../assets/svg/symbol-defs.svg';


const Icon = (props) => {
  return (
    <svg
      className={`icon icon-${props.iconName}`}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <use xlinkHref={`${icons}#icon-${props.iconName}`}></use>
    </svg>
  );
}

export default Icon;