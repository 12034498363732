import './../../styles/index.scss';
import styles from './home.module.scss';
import Header from './../../components/header/Header';
import Banner from './../../components/banner/Banner';
import ModalUp from './../../components/modal-up/ModalUp';
import AboutUs from './../../components/about-us/AboutUs';
import LearnWith from './../../components/learn-with/LearnWith';
import Courses from './../../components/courses/Courses';
import OurCourses from '../../components/forms/courses/OurCourses';
import NewCourses from './../../components/new-courses/NewCourses';
import Teachers from './../../components/teachers/Teachers';
import Reviews from './../../components/reviews/Reviews';
import LessonForms from './../../components/lesson-forms/LessonForms';
import Test from './../../components/test/Test';
import Technical from './../../components/technical/Technical';
import Materials from './../../components/materials/Materials';
import Photos from './../../components/photos/Photos';
import Faq from './../../components/faq/Faq';
import Contacts from './../../components/contacts/Contacts';
import { WaveDown, WaveUp } from './../../components/about-us/waves';
import HelloOtto from './../../components/hello-otto/HelloOtto';
import TrialLesson from './../../components/forms/trial-lesson/TrialLesson';
import { createContext, useState } from 'react';
import classNames from 'classnames';
import Phone from '../../components/phone/Phone';
import PhoneForm from '../../components/forms/phone/PhoneForm';
import RegistrationForm from '../../components/Registration';

export const UserContext = createContext();
export const PhoneContext = createContext();

const Home = () => {
  const [click, setClick] = useState(false);
  const [on, setOn] = useState(false);

  return (
    <div className='container'>
      <div className={click ? styles.courseForm : styles.courseClose}>
        <div className={styles.btnClose} onClick={() => setClick(false)}>
          <div className={styles.line}></div>
        </div>
        <OurCourses />
      </div>
      <div className={on ? styles.courseForm : styles.courseClose}>
        <div className={styles.btnClose} onClick={() => setOn(false)}>
          <div className={styles.line}></div>
        </div>
        <PhoneForm />
      </div>
      <Header />
      {/* <Banner /> */}
      <PhoneContext.Provider value={{ on, setOn }}>
        <Phone click={on} />
      </PhoneContext.Provider>
      <ModalUp />
      <WaveUp />
      {/* <RegistrationForm /> */}
      <AboutUs />
      <WaveDown />
      <HelloOtto />
      <LearnWith />
      <UserContext.Provider value={{ click, setClick }}>
        <Courses click={click} />
      </UserContext.Provider>
      {/* <UserContext.Provider value={{ click, setClick }}>
        <NewCourses click={click} />
      </UserContext.Provider> */}
      <Teachers />
      <Reviews />
      <LessonForms />
      <Test />
      <Technical />
      <Materials />
      <Photos />
      <Faq />
      <Contacts />
    </div>
  );
};

export default Home;
