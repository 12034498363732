import { useEffect, useState } from 'react';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAcxYkBeKSvdJL4bmKZhYJD4sX7PVEVmww',
  authDomain: 'ottohans-admin.firebaseapp.com',
  projectId: 'ottohans-admin',
  storageBucket: 'ottohans-admin.appspot.com',
  messagingSenderId: '219826994208',
  appId: '1:219826994208:web:02a2d2741cdc1b0d871045',
  measurementId: 'G-B37Z2LT01D',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default getFirestore();
const auth = getAuth();

export const signup = (email, password) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const login = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};


export const logout = () => {
  return signOut(auth);
}

// Custom Hook
export const useAuth = () => {
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return unsub;
  }, []);

  return currentUser;
};

