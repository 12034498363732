import { HashLink as Link } from 'react-router-hash-link';
import { useState } from 'react';
import './navbar.scss';
import Icon from './../../elements/icon/Icon';
import styles from './navbar.module.scss';
import classNames from 'classnames';

const Navbar = (props) => {
  const [isOn, setIsOn] = useState(false);

  return (
    <nav className={classNames('navbar', styles.navbar)}>
      <div className={props.navClassName} onClick={props.navOnClick}>
        <Link className='link' to='#about-us'>
          <div className='item'>Про нас</div>
        </Link>
        <Link className='link' to='#courses'>
          <div className='item'>Курси</div>
        </Link>
        <Link className='link' to='#teachers'>
          <div className='item'>Викладачі</div>
        </Link>
        <Link className='link' to='#reviews'>
          <div className='item'>Відгуки</div>
        </Link>
        <Link className='link' to='#contacts'>
          <div className='item'>Контакти</div>
        </Link>

        <div className={classNames('headerSocial', styles.socialMedia)}>
          <div className={styles.circle}>
            <a
              className={styles.link}
              href='https://www.facebook.com/ottohans.sprachstudio/'
            >
              <Icon iconName='facebook' />
            </a>
          </div>
          <div className={styles.circle}>
            <a
              className={styles.link}
              href='https://www.instagram.com/ottohans_/'
            >
              <Icon iconName='instagram' />
            </a>
          </div>
          <div className={styles.circle}>
            <a className={styles.link} href='https://t.me/ottohans_school'>
              <Icon iconName='telegram' />
            </a>
          </div>
        </div>
      </div>

      <div
        className={classNames('menu-button', styles.menuButton)}
        onClick={props.burgerOnClick}
      >
        <div className={props.burgerLineClass}>
          <Icon iconName='burger-line' />
        </div>
        <div className={props.burgerLineClass}>
          <Icon iconName='burger-line' />
        </div>
        <div className={props.burgerLineClass}>
          <Icon iconName='burger-line' />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
