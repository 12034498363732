import styles from './trial-lesson.module.scss';
import { Formik } from 'formik';
import emailjs, { send } from 'emailjs-com';
import Select from 'react-select';

// import {
//   collection,
//   addDoc,
//   setDoc,
//   doc,
//   deleteDoc,
//   updateDoc,
// } from 'firebase/firestore';
// import db from '../../../firebase';

import classNames from 'classnames';

import axios from 'axios';
import { useState } from 'react';

const TrialLesson = () => {
  const [formData, setFormData] = useState({
    clientName: '',
    clientEmail: '',
    clientPhone: '',
    clientNote: '',
    courseUrl: '',
  });
  const [clicked, setClicked] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Константи з даними з консолі організації
    const branchId = '63ff3fef0808e4000a960ba6';
    const courseId = '857a3269-3b66-1d38-9ec4-34e14fa0d0f1';
    const ownerId = '63ff3fef0808e4000a960ba5';
    const token = 'token';

    // Створення об'єкту з даними для відправки
    const data = {
      clientInfo: {
        clientName: formData.clientName,
        clientEmail: formData.clientEmail,
        phone: formData.clientPhone,
        clientNote: formData.clientNote,
      },
      courseUrl: formData.courseUrl,
    };

    // Налаштування конфігурації для axios
    const config = {
      headers: {
        ownerId: ownerId,
        token: token,
      },
    };

    // Виконання POST-запиту за допомогою axios
    axios
      .post(
        `https://api.voopty.com/api/registrations/course/${branchId}/${courseId}`,
        data,
        config
      )
      .then((response) => {
        console.log('Успішно відправлено:', response.data);
        // Додатковий код, який ви хочете виконати після успішної відправки
      })
      .catch((error) => {
        console.error('Помилка відправки:', error);
        // Додатковий код для обробки помилки
      });
  };

  return (
    <div className={styles.container}>
      <div className={clicked ? styles.close : styles.heading}>
        <h2>Введи свої дані і ми з Тобою зв'яжемося: </h2>
      </div>
      <div className={styles.scroll}>
        <form
          onSubmit={handleSubmit}
          id='scrollbar'
          className={clicked ? styles.close : styles.form}
        >
          <div className={styles.formPart}>
            <label
              className={classNames(styles.label, styles.title)}
              htmlFor='clientName'
            >
              1. Введи своє ім'я:
            </label>
            <input
              className={styles.input}
              type='text'
              name='clientName'
              placeholder="Ім'я"
              value={formData.clientName}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.formPart}>
            <label
              className={classNames(styles.label, styles.title)}
              htmlFor='clientEmail'
            >
              2. Введи свій і-мейл:
            </label>
            <input
              className={styles.input}
              type='email'
              name='clientEmail'
              placeholder='student2000@gmail.com'
              value={formData.clientEmail}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.formPart}>
            <label
              className={classNames(styles.label, styles.title)}
              htmlFor='clientPhone'
            >
              3. Введи свій номер телефону:
            </label>
            <input
              className={styles.input}
              type='text'
              name='clientPhone'
              placeholder='+380991234567'
              value={formData.clientPhone}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.formPart}>
            <label
              className={classNames(styles.label, styles.title)}
              htmlFor='course'
            >
              4. Вибери курс, на якому Ти хочеш навчатися:
            </label>
            <select
              name='courseUrl'
              value={formData.courseUrl}
              onChange={handleInputChange}
              style={{ display: 'block' }}
            >
              <option value='' label='Вибір курсу'>
                Вибери курс
              </option>
              <option value='A1 (дорослі)' label='A1 (дорослі)'>
                A1 (дорослі)
              </option>
              <option value='A2 (дорослі)' label='A2 (дорослі)'>
                A2 (дорослі)
              </option>
              <option value='B1 (дорослі)' label='B1 (дорослі)'>
                B1 (дорослі)
              </option>
              <option value='B2 (дорослі)' label='B2 (дорослі)'>
                B2 (дорослі)
              </option>
              <option value='C1 (дорослі)' label='C1 (дорослі)'>
                C1 (дорослі)
              </option>
              <option
                value='Deutschprofis (підлітки: A1-B1)'
                label='Deutschprofis (підлітки: A1-B1)'
              >
                Deutschprofis (підлітки - A1-B1)
              </option>
              <option
                value='Genies (підлітки: A1-C1)'
                label='Genies (підлітки: A1-C1)'
              >
                Genies (A1-C1)
              </option>
              <option
                value='Jana und Dino (дошкільнята)'
                label='Jana und Dino (дошкільнята)'
              >
                Jana und Dino (дошкільнята)
              </option>
              <option value='Не знаю свого рівня' label='Не знаю свого рівня'>
                Не знаю свого рівня
              </option>
            </select>
          </div>
          {/* Чекбокси соцмережі */}
          <div className={styles.formPart}>
            <label
              className={classNames(styles.label, styles.title)}
              htmlFor='course'
            >
              5. Як нам з Тобою зв'язатися?
            </label>
            <select
              name='clientNote'
              value={formData.clientNote}
              onChange={handleInputChange}
              style={{ display: 'block' }}
            >
              <option value='' label='Вибір опції'>
                Вибери опцію
              </option>
              <option value='Viber' label='Viber'>
                Viber
              </option>
              <option value='WhatsApp' label='WhatsApp'>
                WhatsApp
              </option>
              <option value='Telegram' label='Telegram'>
                Telegram
              </option>
              <option value='Зателефонуйте мені' label='Зателефонуйте мені'>
                Зателефонуйте мені
              </option>
              <option value='E-mail' label='E-mail'>
                E-mail
              </option>
            </select>
          </div>
          {/* Кінець */}
          <button
            onClick={() => setClicked(true)}
            type='submit'
            className={styles.btn}
          >
            <span>Відправити</span>
          </button>
        </form>
      </div>
      <div className={clicked ? styles.complete : styles.close}>
        Твоя форма успішно відправлена! Зовсім скоро ми з Тобою зв'яжемося
        &#128540;
      </div>
    </div>
  );
};

export default TrialLesson;

// import styles from './trial-lesson.module.scss';
// import emailjs, { send } from 'emailjs-com';
// import { Formik } from 'formik';
// import { async } from '@firebase/util';
// import {
//   collection,
//   addDoc,
//   setDoc,
//   doc,
//   deleteDoc,
//   updateDoc,
// } from 'firebase/firestore';
// import db from '../../../firebase';

// import { useState } from 'react';
// import classNames from 'classnames';
// import Icon from '../../../elements/icon/Icon';
// import { useRef } from 'react';

// const Errors = {
//   REQUIRED: "* Це поле обов'язкове",
//   INVALID_EMAIL: '* Невалідна назва електронної пошти',
//   INVALID_TEL: '* Невалідний номер телефону',
//   INVALID_NAME: "* Невалідне ім'я",
//   INVALID_SURNAME: '* Невалідне прізвище',
// };

// const TrialLesson = (props) => {
//   const [clicked, setClicked] = useState(false);

//       function SendEmail(object) {
//         emailjs
//           .send(
//             'service_sq6yn4e',
//             'template_dvob3db',
//             object,
//             'fsT5UWXmH8tZQ85MF'
//           )
//           .then(
//             (result) => {
//               console.log(result.text);
//             },
//             (error) => {
//               console.log(error.text);
//             }
//           );
//       }

//   const initialValues = {
//     email: '',
//     tel: '',
//     name: '',
//     surname: '',
//     contactMe: '',
//     LangLevel: '',
//     lessonForm: '',
//   };

//   const validate = (values) => {
//     const errors = {};
//     if (!values.email) {
//       errors.email = Errors.REQUIRED;
//     } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
//       errors.email = Errors.INVALID_EMAIL;
//     }
//     if (!values.tel) {
//       errors.tel = Errors.REQUIRED;
//     } else if (!/[+]|[0-9]{1,14}$/.test(values.tel)) {
//       errors.tel = Errors.INVALID_TEL;
//     }
//     if (!values.name) {
//       errors.name = Errors.REQUIRED;
//     } else if (!/^([a-zA-Z])|([а-яА-Я])/.test(values.name)) {
//       errors.name = Errors.INVALID_NAME;
//     }
//     if (!values.surname) {
//       errors.surname = Errors.REQUIRED;
//     } else if (!/^([a-zA-Z])|([а-яА-Я])/.test(values.surname)) {
//       errors.surname = Errors.INVALID_SURNAME;
//     }
//     return errors;
//   };

//   const submit = async (values, { setSubmitting, setErrors, resetForm }) => {
//     const collectionRef = collection(db, 'users');
//     SendEmail(values);
//     const docRef = await addDoc(collectionRef, values);
//     resetForm();
//   };

//   return (
//     <div>
//       <div className={styles.container}>
//         <div className={clicked ? styles.close : styles.heading}>
//           <h2>Введи свої дані і ми з Тобою зв'яжемося: </h2>
//         </div>
//         <Formik
//           initialValues={initialValues}
//           validate={validate}
//           onSubmit={submit}
//         >
//           {({
//             values,
//             errors,
//             touched,
//             handleChange,
//             handleBlur,
//             handleSubmit,
//             isSubmitting,
//             setFieldValue,
//             /* and other goodies */
//           }) => (
//             <div className={styles.scroll}>
//               <form
//                 id='scrollbar'
//                 onSubmit={handleSubmit}
//                 className={clicked ? styles.close : styles.form}
//               >
//                 {/* Ім'я */}
//                 <div className={styles.formPart}>
//                   <label
//                     className={classNames(styles.label, styles.title)}
//                     htmlFor='name'
//                   >
//                     1. Введи своє ім'я:
//                   </label>
//                   <input
//                     placeholder='Михайло'
//                     id='name'
//                     className={styles.input}
//                     type='text'
//                     name='name'
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     value={values.name}
//                   />
//                   <span className={styles.error}>
//                     {errors.name && touched.name && errors.name}
//                   </span>
//                 </div>
//                 {/* Прізвище */}
//                 <div className={styles.formPart}>
//                   <label
//                     className={classNames(styles.label, styles.title)}
//                     htmlFor='surname'
//                   >
//                     2. Введи своє прізвище:
//                   </label>
//                   <input
//                     placeholder='Шевченко'
//                     id='surname'
//                     className={styles.input}
//                     type='text'
//                     name='surname'
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     value={values.surname}
//                   />
//                   <span className={styles.error}>
//                     {errors.surname && touched.surname && errors.surname}
//                   </span>
//                 </div>
//                 {/* Радіо кнопки - рівень німецької мови */}
//                 <div className={styles.radioBtns}>
//                   <div className={styles.title}>
//                     3. Вкажи свій рівень німецької мови:
//                   </div>
//                   <div className={styles.radioBtnsParts}>
//                     <div className={styles.formPart}>
//                       <label className={styles.label}>
//                         <input
//                           className={styles.radio}
//                           type='radio'
//                           name='LangLevel'
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={'Не маю жодних знань німецької (А0)'}
//                         />
//                         Не маю жодних знань німецької (А0)
//                       </label>
//                     </div>
//                     <div className={styles.formPart}>
//                       <label className={styles.label}>
//                         <input
//                           className={styles.radio}
//                           type='radio'
//                           name='LangLevel'
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={'A1'}
//                         />
//                         A1
//                       </label>
//                     </div>
//                     <div className={styles.formPart}>
//                       <label className={styles.label}>
//                         <input
//                           className={styles.radio}
//                           type='radio'
//                           name='LangLevel'
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={'A2'}
//                         />
//                         A2
//                       </label>
//                     </div>
//                     <div className={styles.formPart}>
//                       <label className={styles.label}>
//                         <input
//                           className={styles.radio}
//                           type='radio'
//                           name='LangLevel'
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={'B1'}
//                         />
//                         B1
//                       </label>
//                     </div>
//                     <div className={styles.formPart}>
//                       <label className={styles.label}>
//                         <input
//                           className={styles.radio}
//                           type='radio'
//                           name='LangLevel'
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={'B2'}
//                         />
//                         B2
//                       </label>
//                     </div>
//                     <div className={styles.formPart}>
//                       <label className={styles.label}>
//                         <input
//                           className={styles.radio}
//                           type='radio'
//                           name='LangLevel'
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={'C1'}
//                         />
//                         C1
//                       </label>
//                     </div>
//                     <div className={styles.formPart}>
//                       <label className={styles.label}>
//                         <input
//                           className={styles.radio}
//                           type='radio'
//                           name='LangLevel'
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={'Не знаю свого рівня'}
//                         />
//                         Не знаю свого рівня
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 {/* Радіо кнопки - форма навчання */}
//                 <div className={styles.radioBtnsForms}>
//                   <div className={styles.title}>
//                     4. Яку форму навчання Ти б хотів обрати?
//                   </div>
//                   <div className={styles.radioBtnsFormsParts}>
//                     <div className={styles.formPart}>
//                       <label className={styles.label}>
//                         <input
//                           className={styles.radio}
//                           type='radio'
//                           name='lessonForm'
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={'Індивідуально з викладачем'}
//                         />
//                         Індивідуально з викладачем
//                       </label>
//                     </div>
//                     <div className={styles.formPart}>
//                       <label className={styles.label}>
//                         <input
//                           className={styles.radio}
//                           type='radio'
//                           name='lessonForm'
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={'Парне заняття'}
//                         />
//                         Парне заняття
//                       </label>
//                     </div>
//                     <div className={styles.formPart}>
//                       <label className={styles.label}>
//                         <input
//                           className={styles.radio}
//                           type='radio'
//                           name='lessonForm'
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={'У групі'}
//                         />
//                         У групі
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 {/* Електронна пошта */}
//                 <div className={styles.formPart}>
//                   <label
//                     className={classNames(styles.label, styles.title)}
//                     htmlFor='email'
//                   >
//                     5. Введи свій і-мейл:
//                   </label>
//                   <input
//                     placeholder='student2000@gmail.com'
//                     id='email'
//                     className={styles.input}
//                     type='email'
//                     name='email'
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     value={values.email}
//                   />
//                   <span className={styles.error}>
//                     {errors.email && touched.email && errors.email}
//                   </span>
//                 </div>
//                 {/* Номер телефону */}
//                 <div className={styles.formPart}>
//                   <label
//                     className={classNames(styles.label, styles.title)}
//                     htmlFor='tel'
//                   >
//                     6. Введи свій номер телефону:
//                   </label>
//                   <input
//                     placeholder='+380661234567'
//                     id='tel'
//                     className={styles.input}
//                     type='tel'
//                     name='tel'
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     value={values.tel}
//                   />
//                   <span className={styles.error}>
//                     {errors.tel && touched.tel && errors.tel}
//                   </span>
//                 </div>
//                 {/* Чекбокс - куди написати */}
//                 <div className={styles.checkboxes}>
//                   <div className={styles.title}>
//                     7. Як нам з Тобою зв'язатися (можливі декілька варіантів):
//                   </div>
//                   <div className={styles.contactMeParts}>
//                     <div className={styles.formPart}>
//                       {/* Вайбер */}
//                       <label className={styles.label}>
//                         <input
//                           className={styles.checkbox}
//                           type='checkbox'
//                           name='contactMe'
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={' Viber '}
//                         />
//                         Viber
//                       </label>
//                     </div>
//                     {/* Вотсап */}
//                     <div className={styles.formPart}>
//                       <label className={styles.label}>
//                         <input
//                           className={styles.checkbox}
//                           type='checkbox'
//                           name='contactMe'
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={' WhatsApp '}
//                         />
//                         WhatsApp
//                       </label>
//                     </div>
//                     {/* Телеграм */}
//                     <div className={styles.formPart}>
//                       <label className={styles.label}>
//                         <input
//                           className={styles.checkbox}
//                           type='checkbox'
//                           name='contactMe'
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={' Telegram '}
//                         />
//                         Telegram
//                       </label>
//                     </div>
//                     {/* По телефону */}
//                     <div className={styles.formPart}>
//                       <label className={styles.label}>
//                         <input
//                           className={styles.checkbox}
//                           type='checkbox'
//                           name='contactMe'
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={' Зателефонуйте мені '}
//                         />
//                         Зателефонуйте мені
//                       </label>
//                     </div>
//                     {/* Через електронну пошту */}
//                     <div className={styles.formPart}>
//                       <label className={styles.label}>
//                         <input
//                           className={styles.checkbox}
//                           type='checkbox'
//                           name='contactMe'
//                           onChange={handleChange}
//                           onBlur={handleBlur}
//                           value={' E-mail '}
//                         />
//                         E-mail
//                       </label>
//                     </div>
//                   </div>
//                 </div>
//                 <button
//                   onClick={() => setClicked(true)}
//                   type='submit'
//                   disabled={isSubmitting}
//                   className={styles.btn}
//                 >
//                   <span>Відправити</span>
//                 </button>
//               </form>
//               <div className={clicked ? styles.complete : styles.close}>
//                 Твоя форма успішно відправлена! Зовсім скоро ми з Тобою
//                 зв'яжемося &#128540;
//               </div>
//             </div>
//           )}
//         </Formik>
//       </div>
//     </div>
//   );
// };

// export default TrialLesson;
