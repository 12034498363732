import { useMemo, useState } from 'react';
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';
import styles from './map.module.scss';
import marker from './../../../assets/img/marker.png';
import mapStyles from './mapStyles';

const InfoWindow = () => {
  return (
    <div className={styles.infoWindow}>
      <h6 className={styles.title}>Мовна школа 'Otto Hans'</h6>
      <div className={styles.description}>
        <div className={styles.city}>м. Івано-Франківськ,</div> <div>вул. Академіка Гнатюка, 22/3</div>
      </div>
      <a
        className={styles.link}
        href='https://www.google.com/maps/place/%D0%9C%D0%BE%D0%B2%D0%BD%D0%B0+%D1%88%D0%BA%D0%BE%D0%BB%D0%B0+%22Otto+Hans%22/@48.9212234,24.7188785,19z/data=!4m5!3m4!1s0x0:0x27e9ac713a0fcb36!8m2!3d48.9212676!4d24.71896?hl=uk'
      >
        Переглянути в Google
      </a>
    </div>
  );
};

const OttoMap = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDQ3iw4n_nVt4c8ymv5IvQwl3bowMwzXfE',
  });

  if (!isLoaded) return <div>Loading</div>;
  return <Map />;
};

const Map = () => {

  const [infoWindow, setInfoWindow] = useState(false);
  return (
    <GoogleMap
      zoom={18}
      options={{ styles: mapStyles, disableDefaultUI: true }}
      center={{ lat: 48.921289262494504, lng: 24.718959304596122 }}
      mapContainerClassName={styles.map}
    >
      {infoWindow === false ? 'no' : <InfoWindow />}
      <MarkerF
        onClick={() =>
          infoWindow === false ? setInfoWindow(true) : setInfoWindow(false)
        }
        className={styles.marker}
        icon={marker}
        title={"Мовна школа 'Otto Hans'"}
        clickable={true}
        position={{ lat: 48.921289262494504, lng: 24.718959304596122 }}
      />
    </GoogleMap>
  );
};

export default OttoMap;
