import { useContext } from 'react';
import Icon from '../../elements/icon/Icon';
import { PhoneContext } from '../../pages/Home/Home';
import styles from './phone.module.scss';
import './phone.scss';
import classNames from 'classnames';

  
const Phone = () => {
const { on, setOn } = useContext(PhoneContext);


  return (
    <div
      className={classNames('phone', styles.phone)}
      onClick={() => setOn('true')}
    >
      <Icon iconName='phone' />
    </div>
  );
};

export default Phone;
