import styles from './card.module.scss';
import classNames from 'classnames';

const Card = (props) => {
  return (
    <div className={classNames(props.className, styles.card)}>
      <div className={props.imageContainerClass}>
        <img src={props.src} alt={props.alt} />
      </div>
      <h3
        className={styles.title}
      >
        {props.title}
      </h3>
      {/* <div className={styles.description}>{props.description}</div> */}
      <div
        className={styles.priceContainer}
      >
        <div className={styles.price}>
          {props.price}
          <span className={styles.currency}>грн/</span>
        </div>
        <div className={styles.time}>
          {props.time}
          <span className={styles.minutes}>хв</span>
        </div>
      </div>
    </div>
  );
};

export default Card;
