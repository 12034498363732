import { HashLink as Link } from 'react-router-hash-link';
import Navbar from '../navbar/Navbar';
import styles from './header.module.scss';
import classNames from 'classnames';
import Icon from './../../elements/icon/Icon';
import './icons.scss';
import { useState } from 'react';
import bannerstyles from './banner.module.scss';
import { Button, ButtonTwo } from '../../elements/button/Button';
import image from './../../assets/img/banner.png';
import './absolute.scss';
import TrialLesson from '../forms/trial-lesson/TrialLesson';

const Header = (props) => {
  const telegram = <Icon iconName='facebook' />;

  const button = {
    text: 'Пробне заняття',
  };
  const [isOn, setIsOn] = useState(false);
  const [click, setClick] = useState(false);

  return (
    <div className={styles.bigContainer}>
      <div className={isOn ? styles.wrapperOn : styles.wrapper}>
        <div id='header' className={styles.headerWrapper}>
          <div className={styles.logoContainer}>
            <Link to='#' className={styles.logo}>
              <Icon iconName='logo' />
            </Link>
            <div className={styles.logoWriting}>
              <Icon iconName='logo-writing' />
            </div>
          </div>
          <div className={styles.navbar}>
            <Navbar
              navClassName={classNames(
                isOn ? 'nav-links-mobile-on' : 'nav-links',
                'nav-links-mobile'
              )}
              navOnClick={() => setIsOn(false)}
              burgerOnClick={() => setIsOn(!isOn)}
              burgerLineClass={!isOn ? styles.burgerLine : styles.burgerOpen}
            />
          </div>
          <div className={classNames('headerSocial', styles.socialMedia)}>
            <div className={styles.circle}>
              <a
                className={styles.link}
                href='https://www.facebook.com/ottohans.sprachstudio/'
              >
                <Icon iconName='facebook' />
              </a>
            </div>
            <div className={styles.circle}>
              <a
                className={styles.link}
                href='https://www.instagram.com/ottohans_/'
              >
                <Icon iconName='instagram' />
              </a>
            </div>
            <div className={styles.circle}>
              <a className={styles.link} href='https://t.me/ottohans_school'>
                <Icon iconName='telegram' />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          isOn ? bannerstyles.wrapperOn : bannerstyles.wrapper
        )}
      >
        <div className={bannerstyles.redDot}></div>
        <div className={bannerstyles.blueDot}></div>
        <div className={bannerstyles.container}>
          <div className='pencilContainer'>
            <Icon iconName='pencil' />
          </div>
          <h1
            className={bannerstyles.title}
          >
            Курси німецької та англійської мови для дорослих і дітей
          </h1>
          <p
            className={bannerstyles.text}
          >
            Мовна школа «Otto Hans» - це Твій вірний крок на зустріч вивченню
            німецької та англійської мови. Наша сертифікована команда
            познайомить Тебе зі світом граматики, а головне допоможе подолати
            мовний бар‘єр і навчить говорити.
          </p>
          <div
            className={styles.buttons}
          >
            <div
              className={bannerstyles.btnContainer}
              onClick={() => setClick(true)}
            >
              <ButtonTwo text={button.text} />
            </div>

            <Button link='https://t.me/ottohans_school' text={'Наш телеграм'} />
          </div>
        </div>
        <div className={bannerstyles.image}>
          <Icon iconName='ruler' />
          <img
            src={image}
            alt='Children playing'
          />
        </div>
      </div>

      <div
        className={
          click ? bannerstyles.trialLessonContainer : bannerstyles.close
        }
      >
        <div className={bannerstyles.btnClose} onClick={() => setClick(false)}>
          <div className={bannerstyles.line}></div>
        </div>
        <TrialLesson />
      </div>
    </div>
  );
};

export default Header;
