import styles from './lesson-forms.module.scss';
import Card from './card/Card';
import Heading from '../../elements/heading/Heading';
import classNames from 'classnames';

import individualImg from './../../assets/img/individual.jpg';
import pairImg from './../../assets/img/pair.jpg';
import groupImg from './../../assets/img/group-2.png';

const individual = {
  title: 'Індивідуально',
  description: 'Займайся із викладачем особисто!',
  price: '600',
  time: '60',
  src: individualImg,
  alt: 'Individual classes',
};

const pair = {
  title: 'У парі',
  description:
    'Займайся разом із другом чи подругою! Або ми знайдемо Тобі напарника.',
  price: '800',
  time: '60',
  src: pairImg,
  alt: 'Pair classes',
};

const group = {
  title: 'У групі',
  description: 'Познайомся із новими людьми у невеликій групі до 9 осіб.',
  price: 'від 200',
  time: '90',
  src: groupImg,
  alt: 'Group classes',
};

const LessonForms = () => {
  return (
    <div className={classNames('wrapper', styles.wrapper)}>
      <div
        data-aos='fade-right'
        data-aos-duration='1000'
        className={styles.heading}
      >
        <Heading name='Заняття у нас можуть відбуватися' />
      </div>
      <div className={styles.formsContainer}>
        <Card
          imageContainerClass={styles.individual}
          className={styles.small}
          src={individual.src}
          alt={individual.alt}
          title={individual.title}
          description={individual.description}
          price={individual.price}
          time={individual.time}
        />
        <Card
          imageContainerClass={styles.pair}
          className={styles.small}
          title={pair.title}
          src={pair.src}
          alt={pair.alt}
          description={pair.description}
          price={pair.price}
          time={pair.time}
        />
        <Card
          imageContainerClass={styles.group}
          className={styles.big}
          title={group.title}
          src={group.src}
          alt={group.alt}
          description={group.description}
          price={group.price}
          time={group.time}
        />
      </div>
    </div>
  );
};

export default LessonForms;
