import classNames from 'classnames';
import styles from './button.module.scss';
import { HashLink as Link } from 'react-router-hash-link';

export function Button(props) {
  return (
    <button className={classNames(styles.button, props.class)}>
      <a className={styles.link} href={props.link}>
        {props.text}
      </a>
    </button>
  );
}

export function ButtonTwo(props) {
  return (
    <Link
      className={classNames(styles.buttonTwo, props.class)}
      to={`#${props.to}`}
    >
      {props.text}
    </Link>
  );
}

