import './styles/index.scss';
import Home from './pages/Home/Home';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Login from './pages/Login/Login';
import Main from './pages/Main/Main';
import NewCourses from './pages/new-courses/NewCourses';
import TrialLesson from './pages/trial-lesson/TrialLesson';
import { useAuth } from './firebase';
import Teachers from './pages/Teachers/Teachers';
import { createContext, useState } from 'react';

function App() {
  const currentUser = useAuth();
  const navigate = useNavigate();
  const navigateToLogin = () => {
    navigate('/');
  };





  return (
    <div className='App'>
      <div className='container'>
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/login' element={<Login />}></Route>
          {/* <Route path='/teachers' element={<Teachers />}></Route> */}

        </Routes>
      </div>
    </div>
  );
}

export default App;
