import styles from './tab.module.scss';
import classNames from 'classnames';
import Icon from '../../../elements/icon/Icon';
import { useContext, useState, createContext } from 'react';
import { ButtonTwo } from '../../../elements/button/Button';
import { UserContext } from './../../../pages/Home/Home';

const Tab = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  // const [click, setClick] = useState(false);
  const { click, setClick } = useContext(UserContext);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={classNames(styles.card)}
    >
      <div
        className={isHovered === false ? styles.pencil : styles.pencilShadow}
      >
        {isHovered === false ? (
          <Icon iconName='pencil' />
        ) : (
          <Icon iconName='pencil-shadow' />
        )}
      </div>
      <div className={styles.titleContainer}>
        <h3 className={classNames(styles.title, props.class)}>{props.title}</h3>
        <div className={styles.duration}>{props.duration}</div>
        <p className={styles.books}>Книжки на курсі:</p>
        <div className={styles.book}>
          <div className={styles.iconCon}>
            <Icon iconName='germany' />
          </div>
          <div>{props.bookGerman}</div>
        </div>
        <div className={styles.book}>
          <div className={styles.iconCon}>
            <Icon iconName='england' />
          </div>
          <div>{props.bookEnglish}</div>
        </div>
      </div>
      <div className={styles.description}>{props.description}</div>
      {isHovered === false ? (
        <div className={styles.container}>
          <div className={styles.text}>Абонемент (8 занять):</div>
          <div className={styles.groups}>
            <div className={styles.priceContainer}>
              <div className={styles.text}>6-8 осіб</div>
              <div className={styles.price}>
                {props.bigGroup}
                <span className={styles.currency}> грн</span>
              </div>
              <div className={styles.smallText}>
                ({props.forOneBig}грн/{props.time})
              </div>
            </div>
            {props.middleGroup && (
              <div className={styles.priceContainer}>
                <div className={styles.text}>5 осіб</div>
                <div className={styles.price}>
                  {props.middleGroup}
                  <span className={styles.currency}> грн</span>
                </div>
                <div className={styles.smallText}>
                  ({props.forOneMiddle}грн/{props.time})
                </div>
              </div>
            )}
            <div className={styles.priceContainer}>
              <div className={styles.text}>3-4 осіб</div>
              <div className={styles.price}>
                {props.smallGroup}
                <span className={styles.currency}> грн</span>
              </div>
              <div className={styles.smallText}>
                ({props.forOneSmall}грн/{props.time})
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.btnContainer} onClick={() => setClick('true')}>
          <ButtonTwo
            class={styles.button}
            text={`Записатись на курс ${props.title}`}
          />
        </div>
      )}
    </div>
  );
};

export default Tab;
