import { async } from '@firebase/util';
import {
  collection,
  addDoc,
  setDoc,
  doc,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import db from '../../firebase';

// ! New button - add new courses for teenagers
export const handleNewTeen = async () => {
  const collectionRef = collection(db, 'teenagers');
  const title = prompt('Enter course title');
  const timetable = prompt('Enter course timetable');
  const teacher = prompt('Enter course teacher');
  const places = prompt('Enter course places');
  const lang = prompt('Enter course language');

  const payload = {
    title,
    timetable,
    teacher,
    places,
    lang,
  };
  const docRef = await addDoc(collectionRef, payload);
};

// ! New button - add new courses for adults
export const handleNewAdult = async () => {
  const collectionRef = collection(db, 'adults');
  const title = prompt('Enter course title');
  const timetable = prompt('Enter course timetable');
  const teacher = prompt('Enter course teacher');
  const places = prompt('Enter course places');
  const lang = prompt('Enter course language');

  const payload = {
    title,
    timetable,
    teacher,
    places,
    lang,
  };
  const docRef = await addDoc(collectionRef, payload);
};

// ! New button - add new courses for children
export const handleNewChild = async () => {
  const collectionRef = collection(db, 'children');
  const title = prompt('Enter course title');
  const timetable = prompt('Enter course timetable');
  const teacher = prompt('Enter course teacher');
  const places = prompt('Enter course places');
  const lang = prompt('Enter course language');

  const payload = {
    title,
    timetable,
    teacher,
    places,
    lang,
  };
  const docRef = await addDoc(collectionRef, payload);
};

// ! Edit button - edit courses for teenagers
export const handleEditTeen = async (id) => {
  const docRef = doc(db, 'teenagers', id);
  const title = prompt('Enter course title');
  const timetable = prompt('Enter course timetable');
  const teacher = prompt('Enter course teacher');
  const places = prompt('Enter course places');
  const lang = prompt('Enter course language');

  const payload = {
    title,
    timetable,
    teacher,
    places,
    lang,
  };
  setDoc(docRef, payload);
};

export const handleEditTeenTitle = async (id) => {
  const docRef = doc(db, 'teenagers', id);
  const title = prompt('Enter course title');

  const payload = {
    title,
  };
  updateDoc(docRef, payload);
};

export const handleEditTeenTimetable = async (id) => {
  const docRef = doc(db, 'teenagers', id);
  const timetable = prompt('Enter course timetable');

  const payload = {
    timetable,
  };
  updateDoc(docRef, payload);
};

export const handleEditTeenDate = async (id) => {
  const docRef = doc(db, 'teenagers', id);
  const teacher = prompt('Enter course teacher');

  const payload = {
    teacher,
  };
  updateDoc(docRef, payload);
};

export const handleEditTeenPlaces = async (id) => {
  const docRef = doc(db, 'teenagers', id);
  const places = prompt('Enter course places');

  const payload = {
    places,
  };
  updateDoc(docRef, payload);
};

export const handleEditTeenLang = async (id) => {
  const docRef = doc(db, 'teenagers', id);
  const lang = prompt('Enter course language');

  const payload = {
    lang,
  };
  updateDoc(docRef, payload);
};

// ! Edit button - edit courses for adults
export const handleEditAdult = async (id) => {
  const docRef = doc(db, 'adults', id);
  const title = prompt('Enter course title');
  const timetable = prompt('Enter course timetable');
  const teacher = prompt('Enter course teacher');
  const places = prompt('Enter course places');
  const lang = prompt('Enter course language');

  const payload = {
    title,
    timetable,
    teacher,
    places,
    lang,
  };
  setDoc(docRef, payload);
};

export const handleEditAdultTitle = async (id) => {
  const docRef = doc(db, 'adults', id);
  const title = prompt('Enter course title');

  const payload = {
    title,
  };
  updateDoc(docRef, payload);
};

export const handleEditAdultTimetable = async (id) => {
  const docRef = doc(db, 'adults', id);
  const timetable = prompt('Enter course timetable');

  const payload = {
    timetable,
  };
  updateDoc(docRef, payload);
};

export const handleEditAdultDate = async (id) => {
  const docRef = doc(db, 'adults', id);
  const teacher = prompt('Enter course teacher');

  const payload = {
    teacher,
  };
  updateDoc(docRef, payload);
};

export const handleEditAdultPlaces = async (id) => {
  const docRef = doc(db, 'adults', id);
  const places = prompt('Enter course places');

  const payload = {
    places,
  };
  updateDoc(docRef, payload);
};

export const handleEditAdultLang = async (id) => {
  const docRef = doc(db, 'adults', id);
  const lang = prompt('Enter course language');

  const payload = {
    lang,
  };
  updateDoc(docRef, payload);
};

// ! Edit button - edit courses for children
export const handleEditChild = async (id) => {
  const docRef = doc(db, 'children', id);
  const title = prompt('Enter course title');
  const timetable = prompt('Enter course timetable');
  const teacher = prompt('Enter course teacher');
  const places = prompt('Enter course places');
  const lang = prompt('Enter course language');

  const payload = {
    title,
    timetable,
    teacher,
    places,
    lang,
  };
  setDoc(docRef, payload);
};

export const handleEditChildTitle = async (id) => {
  const docRef = doc(db, 'children', id);
  const title = prompt('Enter course title');

  const payload = {
    title,
  };
  updateDoc(docRef, payload);
};

export const handleEditChildTimetable = async (id) => {
  const docRef = doc(db, 'children', id);
  const timetable = prompt('Enter course timetable');

  const payload = {
    timetable,
  };
  updateDoc(docRef, payload);
};

export const handleEditChildDate = async (id) => {
  const docRef = doc(db, 'children', id);
  const teacher = prompt('Enter course teacher');

  const payload = {
    teacher,
  };
  updateDoc(docRef, payload);
};

export const handleEditChildPlaces = async (id) => {
  const docRef = doc(db, 'children', id);
  const places = prompt('Enter course places');

  const payload = {
    places,
  };
  updateDoc(docRef, payload);
};

export const handleEditChildrenLang = async (id) => {
  const docRef = doc(db, 'children', id);
  const lang = prompt('Enter course language');

  const payload = {
    lang,
  };
  updateDoc(docRef, payload);
};

// ! Delete button - deletes a particular course for teens
export const handleDeleteTeen = (id) => {
  const docRef = doc(db, 'teenagers', id);
  deleteDoc(docRef);
};

// ! Delete button - deletes a particular course for adults
export const handleDeleteAdult = (id) => {
  const docRef = doc(db, 'adults', id);
  deleteDoc(docRef);
};

// ! Delete button - deletes a particular course for children
export const handleDeleteChild = (id) => {
  const docRef = doc(db, 'children', id);
  deleteDoc(docRef);
};
