import classNames from 'classnames';
import { useState } from 'react';
import Heading from '../../elements/heading/Heading';
import styles from './faq.module.scss';

const Faq = () => {
  const [active, setActive] = useState('');

  const data = [
    {
      question: 'Чи отримаю я сертифікат після завершення курсу?',
      answer:
        'Так, звісно. По завершенню курсу усі студенти складають іспит, який змодельований за стандартами загальноєвропейських вимог. Основними є письмова та усна частини: читання, аудіювання, письмо та говоріння. Отримавши на іспиті 75% балів, можна вважати іспит складеним. Це підтверджується сертифікатом, у якому вказується термін тривалості курсу та кількість прослуханих академічних годин.',
    },
    {
      question: 'Чи потрібно купувати підручник?',
      answer:
        'Вартість підручника не входить у вартість курсу, тому його можна придбати окремо у ліцензованого представника німецьких видавництв, з якими ми співпрацюємо. Це може бути як друкований, так і електронний формат. Підручники лише оригінальні та адаптовані відповідно до вимог загальноєвропейських рамок.',
    },
    {
      question: 'Хто буде моїм викладачем?',
      answer:
        'Ми пропонуємо широкий спектр кваліфікованих викладачів. Кожен із них — спеціалізується у всіх рівнях: від А1 до С1. За період навчання наші студенти встигають познайомитись з усіма викладачами колективу, проте у кожній групі є свій ментор і тютор, котрий проводить кожного студента від нуля аж до глибоких знань.',
    },
    {
      question: 'Яка тривалість заняття?',
      answer:
        'Зазвичай, заняття триває дві академічні години або простіше кажучи 1,5 год. За цей час вивчається матеріал, запланований викладачем, а також проводиться аналіз домашніх завдань та опрацювання завдань, з якими у студентів виникали труднощі.',
    },
    {
      question: 'Я не знаю, який у мене рівень. Який мені курс обрати?',
      answer:
        'Така ситуація трапляється з кожним. Тому до початку формування групи кожному бажаючому зареєструватись на курс чи індивідуальне навчання, ми пропонуємо пройти тестування для визначення рівня.',
    },
    {
      question: 'Що мені потрібно для уроку?',
      answer:
        'Тут існує декілька аспектів. Якщо заняття проходить в онлайн-форматі, тоді важливим є технічна сторона — комп‘ютер, ноутбук, планшет або смартфон. В ідеалі — потрібні навушники та веб-камера, адже основою вивчення мови є комунікація, тому важливим є якісний і налагоджений інтернет зв‘язок. Онлайн-заняття — це не відеолекція. Якщо заняття проходить в форматі живого уроку, тоді достатньо підручника та зошита для нотаток. Ну і головне — це мотивація.',
    },
    {
      question: 'Чи проводите ви пробне заняття?',
      answer:
        'Звичайно, кожен студент має право на безкоштовне пробне заняття у групі, а також 30 хвилин безкоштовного заняття індивідуально.',
    },
    {
      question: 'Ви проводите заняття тільки з дорослими?',
      answer:
        'Ні, у нас доступні заняття як для дітей, так і для дорослих. Щорічно ми випускаємо задоволених малюків із наймолодшої групи, котрі поступово переходять у групи вищого рівня.',
    },
    {
      question: 'Де проходять заняття?',
      answer:
        'Заняття онлайн-формату проходять на зручній та доступній платформі Zoom, яка є легкою та зрозумілою у користуванні й допомагає налагодити відеозв‘язок із нашими студентами. Заняття офлайн-формату проходять у сучасному комфортабельному офісі у центрі міста за адресою м. Івано-Франківськ, вул. Гнатюка, 22.',
    },
  ];



return (
  <div className={classNames('wrapper', styles.wrapper)}>
    <div
      data-aos='zoom-in-right'
      data-aos-duration='1100'
      className={styles.heading}
    >
      <Heading name='Відповіді на поширені запитання' />
    </div>

    <div className={styles.accordion}>
      {data.map((item, index) => (
        <div>
          <div
            key={index}
            className={classNames(
              styles.item,
              active === item.question ? styles.open : ''
            )}
            onClick={() =>
              item.question !== active
                ? setActive(item.question)
                : setActive('')
            }
          >
            <h5 className={styles.smallHeading}>{item.question}</h5>

            <p
              className={classNames(
                styles.answer,
                active === item.question ? styles.slideDown : ''
              )}
            >
              {item.answer}
            </p>
          </div>
        </div>
      ))}
    </div>
  </div>
);
  
};

export default Faq;
