import { async } from '@firebase/util';
import {
  collection,
  addDoc,
  setDoc,
  doc,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import db from '../../firebase';


// ! Delete button - deletes a particular user card
export const handleDeleteUser = (id) => {
  const docRef = doc(db, 'phoneCalls', id);
  deleteDoc(docRef);
};

