// ClassNames
import classNames from 'classnames';

// Swiper modules
import { Navigation, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Icon
import Icon from '../../elements/icon/Icon';

// Import module styles
import styles from './teachers.module.scss';

// Import Swiper SCSS
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/autoplay';

import './swiper.scss';

// Images
import tetianaBoss from './../../assets/img/teachers/tetiana-boss.JPG';
import vika from './../../assets/img/teachers/vika.jpg';
import khrystyna from './../../assets/img/teachers/khrystia.jpg';
import marianaKudliak from './../../assets/img/teachers/mariana-2.jpg';
import marianaSoliuk from './../../assets/img/teachers/mariana.jpg';
import svitlana from './../../assets/img/teachers/svitlana.png';
import vira from './../../assets/img/teachers/vira.jpg';
import roksolana from './../../assets/img/teachers/roksolana.jpg';
import nastia from './../../assets/img/teachers/nastia.JPG';
import tetiana from './../../assets/img/teachers/tetiana.JPG';
import daryna from './../../assets/img/teachers/daryna.JPG';
import julia from './../../assets/img/teachers/julia.jpg';
import sofia from './../../assets/img/teachers/sofia.jpg';


import Heading from '../../elements/heading/Heading';

const Teachers = () => {
  const teachers = [
    {
      image: tetianaBoss,
      alt: 'Tetiana Plekhtiak',
      info: 'Заснувала мовну школу, яка працює за світовими стандартами якості. Найкращі викладачі, атмосфера та професіоналізм у всьому — кредо Тетяни',
      name: 'Тетяна',
      description: [
        'засновниця мовної школи',
        'Goethe Zertifikat C1, TestDaf, DSH',
        'людина своєї справи',
      ],
      language: 'german',
    },
    {
      image: khrystyna,
      alt: 'Khrystyna Shcherba',
      info: 'Ділиться практичними вміннями з викладачами, оскільки її досвід роботи - понад 10 років. Викладає як онлайн, так і офлайн. Про все, що стосується новинок, дізнається першою.',
      name: 'Христина',
      description: [
        'має вищу філологічну освіту',
        'рівень мови С1',
        'досвід роботи понад 10 років',
      ],
      language: 'german',
    },
    {
      image: sofia,
      alt: 'Sofia Holub',
      info: 'Вивчає мову з раннього дитинства. На уроках активно використовує скоромовки та цитати, щоб зробити навчання ще ефективнішим. Мета - допомагати студентам впевнено опановувати англійську мову.',
      name: 'Софія',
      description: [
        'рівень мови С1',
        'працює з дорослими',
        'складала міжнародний іспит',
      ],
      language: 'english',
    },
    {
      image: vira,
      alt: 'Vira Matiiash',
      info: 'Поєднує гумор і викладання, а також рок-душа нашої команди. Цікавинки та нові методи комунікації — Вірин козир у рукаві.',
      name: 'Віра',
      description: [
        'технічна підтримка команди',
        'провела понад 1000 занять',
        'сертифікований рівень С1',
      ],
      language: 'german',
    },
    {
      image: roksolana,
      alt: 'Roksolana Shcherba',
      info: 'Роксолана знаходить підхід до кожного, вміє зацікавити до вивчення мови та використовує на заняттях ігрові методи.',
      name: 'Роксолана',
      description: [
        'працює з наймолодшими',
        'наділена терпінням і розумінням',
        'рівень мови С1',
      ],
      language: 'german',
    },
    {
      image: marianaKudliak,
      alt: 'Mariana Kudliak',
      info: "Хто сказав, що гумор не можна поєднати з німецькою мовою? Саме це створює настрій на заняттях. Мар'яна - улюблениця школярів і підлітків.",
      name: "Мар'яна",
      description: [
        'організатор свят і квестів',
        'робота в мовній школі Швейцарії',
        'рівень мови С1',
      ],
      language: 'german',
    },
    {
      image: julia,
      alt: 'Julia Pitukh',
      info: 'На своїх уроках Юлія створює динамічне та позитивне навчальне середовище, де кожен учень може розкрити свій потенціал. Полюбляє збирати різноманітні вислови та фразеологізми.',
      name: 'Юлія',
      description: [
        'рівень мови С1',
        'вища філологічна освіта',
        'досвід роботи понад 10 років',
      ],
      language: 'english',
    },
    {
      image: svitlana,
      alt: 'Svitlana',
      info: 'Новачок у команді, проте досвідчений педагог, який вносить цікавості до колективу. Стриманість і німецька обережність  — це про нашу Світлану.',
      name: 'Світлана',
      description: [
        'вища філологічна освіта',
        'досвід у міжнародних компаніях',
        'рівень мови С1',
      ],
      language: 'german',
    },
    {
      image: marianaSoliuk,
      alt: 'Mariana Soliuk',
      info: "Мар'яна до навчального процесу підходить дуже ретельно. Якщо цікаво дізнатись історичну сторону німецької мови чи повправлятись у фразеологізмах, Мар’яна у цьому першокласний спеціаліст.",
      name: "Мар'яна",
      description: [
        'поєднує викладання та науку',
        'мовний рівень С1',
        'досвід роботи понад 10 років',
      ],
      language: 'german',
    },
    {
      image: vika,
      alt: 'Viktoria Tertyshna',
      info: 'Сучасний підхід та методики - запорука успішного та ефективного вивчення мови. Полюбляє залучати фільми, мультики та пісні при вивченні мови. Головна мета на заняттях - розговорити учнів.',
      name: 'Віка',
      description: [
        'мовний рівень C1',
        'шаленіє від дитячих жартів',
        'цікавиться історією англійської мови',
      ],
      language: 'english',
    },
    {
      image: nastia,
      alt: 'Anastasia Bodnar',
      info: 'Анастасія відчуває відповідальність за Твій розвиток у німецькій мові. На уроках завжди приємна та дружня атмосфера. Вивчення німецької мови-задоволення. Твої досягнення ще більше  мотивують Анастасію.',
      name: 'Анастасія',
      description: [
        'філологічна та психологічна освіта',
        'рівень мови B2',
        'досвід понад 3 роки ',
      ],
      language: 'german',
    },
    {
      image: tetiana,
      alt: 'Tetiana',
      info: 'Головна ціль — розговорити кожного, тому увага надається саме комунікативній методиці. Інтеративні завдання, ігри, сучасні статті з німецькомовних журналів роблять урок неповторним.',
      name: 'Тетяна',
      description: [
        'філологічна освіта',
        'працювала у швейцарській фірмі',
        'досвід викладання - 5 років',
      ],
      language: 'german',
    },
    {
      image: daryna,
      alt: 'Daryna',
      info: 'Комунікабельність та відкритість допомагає створити приємну атмосферу на заняттях. Використання онлайн ресурсів роблять вивчення мови приємнішим, а заняття - динамічними.',
      name: 'Дарина',
      description: [
        'рівень мови - В2',
        'психологічна та філологічна освіта',
        'обожнює оформлювати онлайн-дошки',
      ],
      language: 'german',
    },
  ];

  const desc = teachers.description;

  return (
    <div id='teachers' className={classNames('wrapper', styles.wrapper)}>
      <div
        data-aos='fade-right'
        data-aos-duration='500'
        className={styles.heading}
      >
        <Heading name='Викладачі' />
      </div>
      <Swiper
        modules={[Navigation, A11y, Autoplay]}
        slidesPerView={'auto'}
        breakpoints={{
          1000: {
            spaceBetween: 30,
          },
          1440: {
            spaceBetween: 40,
          },
        }}
        autoplay
        speed={700}
        loop={true}
        navigation
        className='mySwiper'
      >
        {teachers.map((teacher) => (
          <SwiperSlide>
            <div className={styles.card}>
              <div className={styles.imgContainer}>
                <div className={styles.info}>
                  <p className={styles.text}>{teacher.info}</p>
                </div>
                <img src={teacher.image} alt={teacher.alt} />
              </div>
              <div className={styles.descriptionContainer}>
                <h3 className={styles.name}>
                  <div className={styles.iconCon}>
                    <Icon
                      iconName={
                        teacher.language === 'german' ? 'germany' : 'england'
                      }
                    />
                  </div>
                  {teacher.name}
                </h3>
                <ul className={styles.list}>
                  {teacher.description.map((item, index) => (
                    <li key={`li-${index}`} className={styles.item}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Teachers;
