import styles from './test.module.scss';
import classnames from 'classnames';
import Heading from '../../elements/heading/Heading';
import Icon from './../../elements/icon/Icon';
import { Button } from '../../elements/button/Button';

const Test = () => {
  return (
    <div className={classnames('wrapper', styles.wrapper)}>
      <div
        data-aos-duration='700'
        data-aos='zoom-in'
        className={styles.triangle}
      >
        <Icon iconName='triangle' />
      </div>
      <div
        data-aos-duration='700'
        data-aos='zoom-in'
        className={styles.heading}
      >
        <Heading name='Не знаєш, який у Тебе рівень німецької? Не біда!' />
      </div>
      <div
        className={styles.description}
      >
        Пройди тестування на онлайн-платформі:
      </div>
      <div className={styles.btnContainer}>
        <div

          className={styles.arrowLeft}
        >
          <Icon iconName='test-arrow-left' />
        </div>
        <div
          className={styles.arrowRight}
        >
          <Icon iconName='test-arrow-right' />
        </div>
        <div>
          <Button
            link='https://einstufungstests.klett-sprachen.de/eks/einstufungstest-netzwerkneu-a1/'
            text='Пройти тест'
          />
        </div>
      </div>
    </div>
  );
};

export default Test;
