import classNames from 'classnames';
import { onSnapshot, collection } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import styles from './phone-admin.module.scss';
import db from '../../firebase';
import {
  handleDeleteUser,
} from './Utils';
import { useNavigate } from 'react-router-dom';

const PhoneAdmin = (props) => {
  const [trialLesson, setTrialLesson] = useState([
    { title: 'Loading...', id: 'initial' },
  ]);

  // ! Get infos from the data base
  useEffect(
    () =>
      onSnapshot(collection(db, 'phoneCalls'), (snapshot) => {
        setTrialLesson(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      }),
    []
  );

  return (
    <div className={classNames('wrapper', styles.wrapper)}>
      <h2 className={styles.mainHeading}>Передзвоніть мені</h2>
      <div className={styles.cards}>
        {trialLesson.map((course) => (
          <div key={course.id} className={styles.card}>
            <div>{'Юзер №' + (trialLesson.indexOf(course) + 1)}</div>
            <div className={styles.textContainer}>
              <div className={styles.infos}>
                <span className={styles.title}>Ім'я:</span> {course.name}
              </div>
              <div className={styles.infos}>
                <span className={styles.title}>Прізвище:</span> {course.surname}
              </div>
              <div className={styles.infos}>
                <span className={styles.title}>Телефон:</span> {course.tel}
              </div>
            </div>
            <button
              onClick={() => handleDeleteUser(course.id)}
              className={styles.deleteBtn}
            >
              <span>Видалити</span>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhoneAdmin;
