import styles from './photos.module.scss';
import './slider-photos.scss';

import { useState } from 'react';

// Swiper modules
import { Navigation, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide, useSwiperSlide } from 'swiper/react';

// Import Swiper SCSS
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/autoplay';

// Images
import schoolOne from './../../assets/img/school/school-1.jpg';
import schoolTwo from './../../assets/img/school/school-2.jpg';
import schoolThree from './../../assets/img/school/school-3.jpg';
import schoolFour from './../../assets/img/school/school-4.jpg';
import schoolFive from './../../assets/img/school/school-5.jpg';
import schoolSix from './../../assets/img/school/school-6.jpg';
import schoolSeven from './../../assets/img/school/school-7.jpg';
import schoolEight from './../../assets/img/school/school-8.jpg';
import schoolNine from './../../assets/img/school/school-9.jpg';
import schoolTen from './../../assets/img/school/school-10.jpg';
import schoolEleven from './../../assets/img/school/school-11.jpg';
import schoolThirteen from './../../assets/img/school/school-13.jpg';

const PhotosDesktop = () => {
  const [count, setCount] = useState(1);

  function handleClick() {
    return setCount(count + 1);
  }

  const swiperSlide = useSwiperSlide();

  const photos = [
    {
      id: 1,
      src: schoolOne,
      alt: 'Team of OttoHans',
    },
    {
      id: 2,
      src: schoolTwo,
      alt: 'Team of OttoHans',
    },
    {
      id: 3,
      src: schoolThree,
      alt: 'Team of OttoHans',
    },
    {
      id: 4,
      src: schoolFour,
      alt: 'Team of OttoHans',
    },
    {
      id: 5,
      src: schoolFive,
      alt: 'Team of OttoHans',
    },
    {
      id: 6,
      src: schoolSix,
      alt: 'Team of OttoHans',
    },
    {
      id: 7,
      src: schoolSeven,
      alt: 'Team of OttoHans',
    },
    {
      id: 8,
      src: schoolEight,
      alt: 'Team of OttoHans',
    },
    {
      id: 9,
      src: schoolNine,
      alt: 'Team of OttoHans',
    },
    {
      id: 10,
      src: schoolTen,
      alt: 'Team of OttoHans',
    },
    {
      id: 11,
      src: schoolEleven,
      alt: 'Team of OttoHans',
    },
    {
      id: 13,
      src: schoolThirteen,
      alt: 'Team of OttoHans',
    },
  ];

  return (
    <div className={styles.desktop}>
      <Swiper
        onClick={handleClick}
        modules={[Navigation, A11y]}
        slidesPerView={1}
        navigation
        watchActiveIndex={true}
        className='photosSwiper'
      >
        {photos.map((photo, index) => (
          <SwiperSlide virtualIndex={index}>
            <div className={styles.photosNumber}>
              {index + 1}/{photos.length}
            </div>
            <div className={styles.image}>
              <img src={photo.src} alt={photo.alt} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default PhotosDesktop;
