import Heading from '../../elements/heading/Heading';
import styles from './technical.module.scss';
import Icon from './../../elements/icon/Icon';
import classNames from 'classnames';
import laptop from './../../assets/img/laptop.png';
import girlWithHeadphones from './../../assets/img/girl-with-headphones.png';

const technicalRequirement = {
  one: 'Комп’ютер / ноутбук або планшет',
  two: 'Навушники з мікрофоном',
  twoSecond:
    '(завчасно перевір - чи добре чути Тебе, чи добре чути Твого співбесідника).',
  three: 'Завчасно самостійно встанови Zoom на свій пристрій.',
  four: 'Переконайся, чи Твоє інтернет-з’єднання є стабільним.',
  five: 'Переконайся, що Твоя камера працює.',
};

const Technical = () => {
  return (
    <div className={classNames('wrapper', styles.wrapper)}>
      <div
        className={styles.heading}
      >
        <Heading name='Технічні передумови для участі в онлайн-курсі:' />
      </div>
      <div
        className={styles.cardsContainer}
      >
        <div className={styles.card}>
          <div className={styles.iconContainer}>
            <Icon iconName='star' />
          </div>
          <div className={styles.laptopContainer}>
            <img className={styles.laptop} src={laptop} alt='Laptop' />
          </div>
          <div className={styles.one}>{technicalRequirement.one}</div>
        </div>
        <div className={styles.card}>
          <div className={styles.iconContainer}>
            <Icon iconName='star' />
          </div>
          <div className={styles.girlWithHeadphones}>
            <img src={girlWithHeadphones} alt='Girl With Headphones' />
          </div>
          <div className={styles.two}>
            <span>{technicalRequirement.two}</span> <br />
            <p>{technicalRequirement.twoSecond}</p>
          </div>
        </div>
      </div>
      <div
        className={styles.reqContainer}
      >
        <div className={styles.zoom}>
          <div className={styles.req}>
            <div className={styles.circleContainer}>
              <span className={styles.number}>1</span>
            </div>
            <div className={styles.text}>{technicalRequirement.three}</div>
          </div>
          <div className={styles.zoomCard}>
            <div className={styles.linkContainer}>
              <div className={styles.arrow}>
                <Icon iconName='arrow-transparent' />
              </div>
              <div className={styles.shadow}></div>
              <a
                className={styles.link}
                href='https://zoom.us/support/download'
              >
                Скачати ZOOM
              </a>
            </div>
          </div>
        </div>
        <div className={styles.req}>
          <div className={styles.circleContainer}>
            <span className={styles.number}>2</span>
          </div>
          <div className={styles.text}>{technicalRequirement.four}</div>
        </div>
        <div className={styles.req}>
          <div className={styles.circleContainer}>
            <span className={styles.number}>3</span>
          </div>
          <div className={styles.text}>{technicalRequirement.five}</div>
        </div>
      </div>
    </div>
  );
};

export default Technical;
