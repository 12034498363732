import { useRef, useState } from 'react';
import { login, logout, useAuth } from '../../firebase';
import styles from './login.module.scss';
import Main from '../Main/Main';
// import NewCourses from '../new-courses/NewCourses';
import TrialLesson from '../../pages/trial-lesson/TrialLesson';
import OurCourses from '../our-courses/OurCourses';
import NewCoursesMobile from '../new-courses/NewCoursesMobile';
import PhoneAdmin from '../phone/PhoneAdmin';

const Login = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [loading, setLoading] = useState(false);
  const currentUser = useAuth();

  // ! Log In
  const handleLogIn = async () => {
    setLoading(true);
    try {
      await login(emailRef.current.value, passwordRef.current.value);
    } catch {
      alert('Error!');
    }
    setLoading(false);
  };

  // ! Log Out
  const handleLogOut = async () => {
    setLoading(true);
    try {
      await logout();
    } catch {
      alert('Errroooorrr!');
    }
    setLoading(false);
  };

  return (
    <div
      className={!currentUser ? styles.containerWithoutUser : styles.container}
    >
      <div className={styles.header}>
        {currentUser?.email === 'tetyana.plekhtyak@gmail.com' ? (
          <div className={styles.loggedAs}>
            Ви зайшли як: <span className={styles.name}>Бос</span>
          </div>
        ) : (
          ''
        )}
        {currentUser?.email === 'virko1996@gmail.com' ? (
          <div className={styles.loggedAs}>
            Ви зайшли як: <span className={styles.name}>Віра</span>
          </div>
        ) : (
          ''
        )}
        {currentUser?.email === 'ottohans.school@gmail.com' ? (
          <div className={styles.loggedAs}>
            Ви зайшли як: <span className={styles.name}>Адмін</span>
          </div>
        ) : (
          ''
        )}
        <div className={currentUser ? styles.authLogged : styles.auth}>
          {!currentUser ? (
            <div className={styles.fields}>
              <input
                ref={emailRef}
                placeholder='Email'
                className={styles.field}
              />
              <input
                ref={passwordRef}
                type='password'
                placeholder='Password'
                className={styles.field}
              />
            </div>
          ) : (
            ''
          )}
          <div className={styles.buttons}>
            {/* <button disabled={loading || currentUser} onClick={handleSignUp}>
        Sign Up
      </button> */}
            <button
              disabled={loading || currentUser}
              onClick={handleLogIn}
              className={styles.button}
            >
              <span className={styles.text}>Зайти</span>
            </button>
            <button
              disabled={loading || !currentUser}
              onClick={handleLogOut}
              className={styles.button}
            >
              <span className={styles.text}>Вийти</span>
            </button>
          </div>
        </div>
      </div>

      {currentUser?.email === 'tetyana.plekhtyak@gmail.com' ||
      currentUser?.email === 'virko1996@gmail.com' ||
      currentUser?.email === 'ottohans.school@gmail.com' ? (
        <div className={styles.courses}>
          {/* <NewCourses /> */}
          <NewCoursesMobile />
          <div className={styles.line}></div>
          <TrialLesson />
          <div className={styles.line}></div>
          <OurCourses />
          <div className={styles.line}></div>
          <PhoneAdmin />
          <div className={styles.line}></div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Login;
