import classNames from 'classnames';
import styles from './photos.module.scss';
import PhotosDesktop from './PhotosDesktop';
import PhotosMobile from './PhotosMobile';

const Photos = () => {
  return <div className={classNames(styles.wrapper)}>
    <PhotosDesktop />
    <PhotosMobile/>
  </div>;
};

export default Photos;
