import styles from './hello-otto.module.scss';
import classNames from 'classnames';
import Icon from './../../elements/icon/Icon';

const HelloOtto = () => {
  return (
    <div className={classNames('wrapper', styles.wrapper)}>
      <div className={styles.ruler}>
        <Icon iconName='ruler' />
      </div>
      <div className={styles.redDot}></div>
      <div className={styles.blueDot}></div>
      <div className={styles.imagesContainer}>
        <div className={styles.teamFrame}></div>
        <div className={styles.schoolgirls}></div>
        <div className={styles.schoolgirl}></div>
        <div className={styles.otto}>
          <div className={styles.triangleContainer}>
            <Icon iconName='triangle' />
          </div>
          <div className={styles.writingContainer}>
            <Icon iconName='logo-writing' />
          </div>
        </div>
      </div>
      <div
        className={styles.textContainer}
        data-aos='fade-right'
        data-aos-duration='500'
      >
        <h5 className={styles.title}>Привіт! Ми мовна школа OttoHans. </h5>
        <div className={styles.text}>
          З 2016 року знайомимо наших учнів та студентів з неймовірним світом
          німецької та англійської мови.
        </div>
        <div
          className={styles.text}
        >
          Ми - путівник для понад 2 тисяч студентів. Щорічно випускаємо близько
          50 груп задоволених учнів.
        </div>
        <div className={styles.text}>
          {' '}
          Тому let’s go знайомитись!
        </div>
      </div>
    </div>
  );
};

export default HelloOtto;
