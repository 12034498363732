import classNames from 'classnames';
import Heading from '../../elements/heading/Heading';
import Icon from '../../elements/icon/Icon';
import styles from './about-us.module.scss';
import './icon.scss';
import './about-us-swiper.scss';

// Swiper modules
import {} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper SCSS
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/autoplay';

const cards = [
  {
    icon: <Icon iconName='bot' />,
    heading: 'Від 2 до 102 років',
    text: 'Курси для усіх вікових категорій',
  },
  {
    icon: <Icon iconName='cool-emoji' />,
    heading: 'Мовна родина',
    text: 'Усі викладачі — фахівці своєї справи, котрі орієнтуються на студентів',
  },
  {
    icon: <Icon iconName='piggy-bank' />,
    heading: 'Доступні ціни',
    text: 'Навчання за оптимальною вартістю',
  },
  {
    icon: <Icon iconName='coffee-cup' />,
    heading: 'Зручний формат',
    text: 'Навчання онлайн, офлайн; індивідуальні, парні та групові заняття',
  },
  {
    icon: <Icon iconName='volume' />,
    heading: 'Поговоримо?',
    text: 'Велика кількість розмовної практики',
  },
  {
    icon: <Icon iconName='materials' />,
    heading: 'Сучасні матеріали',
    text: 'Підручники, відео та аудіо, зручна форма для вивчення слів та ігри',
  },
];

const AboutUs = () => {
  return (
    <div id='about-us' className={classNames('wrapper', styles.wrapper)}>
      <Swiper
        spaceBetween={20}
        slidesPerView={'auto'}
        navigation
        className='aboutUsSwiper'
      >
        <div
          className={styles.cards}
        >
          {cards.map((card) => (
            <SwiperSlide>
              <div className={styles.cardContainer}>
                <div
                  className={classNames(
                    styles.iconContainer,
                    'aboutUsIconContainer'
                  )}
                >
                  {card.icon}
                </div>
                <h4 className={styles.heading}>{card.heading}</h4>
                <div className={styles.text}>{card.text}</div>
              </div>
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
    </div>
  );
};

export default AboutUs;
