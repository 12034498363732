import Heading from '../../elements/heading/Heading';
import styles from './materials.module.scss';
import Icon from './../../elements/icon/Icon';
import classNames from 'classnames';
import { useState } from 'react';

const Materials = () => {
  const [isOpen, setIsOpen] = useState('false');

  const cards = [
    {
      title: 'Основні питання А1 з відповідями',
      text: 'Повтори основні запитання за курс А1 з відповідями.',
      link: 'https://padlet.com/virko1996/a1-1-phrasen-3fn9zqpekpwri2su',
    },
    {
      title: '"Секрети домашніх тварин"',
      text: 'Тут Ти знайдеш лексику до мультфільму.',
      link: 'https://quizlet.com/_ck8n5d?x=1jqt&i=24q751',
    },
    {
      title: 'Книги для початківців',
      text: 'Читай книги німецькою на рівнях А1/А2.',
      link: 'https://drive.google.com/drive/folders/15nobd08mLE4zrQxGCWsG2E_TdAy0frTA?usp=share_link',
    },
    {
      title: 'Підготовка до Goethe-Zertifikat A1',
      text: 'Книга з підготовки до екзамену Goethe A1 + аудіо.',
      link: 'https://drive.google.com/drive/folders/1Y_N7uRKiOe_j1Hu5lJXFg4Aqs0qCQqql?usp=share_link',
    },
  ];

  const extraCards = [
    {
      title: 'Прикметники (опис людини)',
      text: 'Тут Ти знайдеш Quizlet із словами до теми.',
      link: 'https://quizlet.com/_b8hz8j?x=1jqt&i=24q751',
    },
    {
      title: 'Граматика до теми "Годинник"',
      text: 'Навчись казати час німецькою за конспектом.',
      link: 'https://docs.google.com/document/d/1ZeWo8HNJA_ygNS22hWLZCZ6-QdDbiFY9/edit?usp=share_link&ouid=101165626404137870844&rtpof=true&sd=true',
    },
    {
      title: 'Ступені порівняння прикметників',
      text: 'Найбільш вживані прикметники та їхні ступені.',
      link: 'https://docs.google.com/document/d/1qENTcrZZvWYOsOaU4BpZMUcYU2vNJYpH/edit?usp=share_link&ouid=101165626404137870844&rtpof=true&sd=true',
    },
    {
      title: 'Теперішній час (Präsens)',
      text: 'Граматичний конспект до теми "Теперішній час".',
      link: 'https://docs.google.com/document/d/1AhkCji1ayft_2CljD1B-EPwymEADoHZa/edit?usp=share_link&ouid=101165626404137870844&rtpof=true&sd=true',
    },
  ];

  return (
    <div className={classNames('wrapper', styles.wrapper)}>
      <div
        data-aos='zoom-in-right'
        data-aos-duration='1100'
        className={styles.textContainer}
      >
        <Heading name='Корисні матеріали' />
        <div className={styles.description}>
          Хочеш іще прокачати свою німецьку? Тоді лови декілька допоміжних
          засобів:
        </div>
      </div>
      <div className={styles.cardsContainer}>
        {cards.map((card) => (
          <a
            className={styles.linkSize}
            href={card.link}
          >
            <div className={styles.card}>
              <div className={styles.iconContainer}>
                <Icon iconName='ruler-2' />
              </div>
              <h3 className={styles.title}>{card.title}</h3>
              <p className={styles.text}>{card.text}</p>
              <div className={styles.linkContainer}>
                <div className={styles.shadow}></div>
                <p className={styles.link}>Перейти</p>
              </div>
            </div>
          </a>
        ))}
      </div>

      <div
        className={classNames(
          styles.extraCardsContainer,
          isOpen === true ? styles.open : ''
        )}
      >
        {extraCards.map((card) => (
          <a className={styles.linkSize} href={card.link}>
            <div className={styles.card}>
              <div className={styles.iconContainer}>
                <Icon iconName='ruler-2' />
              </div>
              <h3 className={styles.title}>{card.title}</h3>
              <p className={styles.text}>{card.text}</p>
              <div className={styles.linkContainer}>
                <div className={styles.shadow}></div>
                <p className={styles.link}>Перейти</p>
              </div>
            </div>
          </a>
        ))}
      </div>
      <button
        className={styles.button}
        onClick={() => (isOpen !== true ? setIsOpen(true) : setIsOpen(''))}
      >
        <span>
          {isOpen !== true ? `Показати ще ${extraCards.length}` : 'Згорнути'}
        </span>
      </button>
    </div>
  );
};

export default Materials;
